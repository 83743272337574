<template>
  <div
    v-if="data.length"
    :class="{ 'quals-actions--padded': padded }"
    class="qual-actions"
  >
    <div v-if="category" class="paragraph-sm">
      {{ category | capitalize }}
    </div>
    <h3 v-if="title" class="title title--xs">
      {{ title | capitalize }}
    </h3>

    <ButtonIcon
      v-for="(item, index) in data"
      :key="index"
      :data-test="`cta-panel-title_${index}`"
      :class="buttonClass(item) + ' cta-panel__button_' + index"
      :href="item.link"
      width="fullwidth"
      :icon="item.icon"
      :icon-left="item.iconLeft"
    >
      {{ item.text }}
    </ButtonIcon>
  </div>
</template>

<script>
import { ButtonIcon } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    ButtonIcon,
  },
  props: {
    data: {
      type: Array,
      default: () => [{}],
    },
    category: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    padded: {
      type: Boolean,
      default: false,
    },
    intakeMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    buttonClass(item) {
      return item.class ? item.class : '';
    },
  },
};
</script>
